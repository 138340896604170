
import { defineComponent } from "vue";
import { Field, Form } from "vee-validate";
import { ElMessage } from "element-plus/lib/components/message";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";

import { ICreateUpdateUserForm } from "@/models/User";

import AccountModule from "@/store/modules/Account";

import { object, string, number } from "yup";

export default defineComponent({
  components: {
    Form,
    Field,
    BaseSvgIcon,
  },
  data() {
    return {
      imageUrl: "",
      schema: object({
        FirstName: string().required().label("First Name"),
        LastName: string().required().label("Last Name"),
        Email: string().required().email().label("Email"),
        Phone: number().required().label("Phone Number"),
      }),
    };
  },
  methods: {
    async onSubmit(values: any, actions: any) {
      await AccountModule.createAccount(values as ICreateUpdateUserForm);
      if (!AccountModule.isError) {
        actions.resetForm();
      }
    },
    handleUploadImageSuccess(res: any, file: any, field: any) {
      console.log(URL.createObjectURL(file.raw));
    },
    beforeUploadImage(file: any) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        ElMessage.error("Avatar picture must be JPG format!");
      }
      if (!isLt2M) {
        ElMessage.error("Avatar picture size can not exceed 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
});
